<template>
    <div class="flex flex-1 flex-col items-center">
        <div class="card w-full" style="max-width: 800px">
            <header class="flex items-center justify-between h-10 border-b border-solid border-gray-200 pb-4 mb-5">
                <h1>{{ $t('clients.billing_information') }}</h1>
            </header>
            <el-form
                ref="form"
                v-loading="$wait.is('loading_billing_information')"
                :model="billingData"
                :rules="rules"
                label-position="top"
                :validate-on-rule-change="false"
                class="px-16"
                element-loading-spinner="el-custom-spinner"
            >
                <div class="border-b border-solid border-gray-200 pb-4 mb-5">
                    <el-form-item :label="$t('clients.enable_billing_for_that_client')">
                        <el-switch v-model="enabledBilling" :disabled="paymentMethods.length == 0" />
                    </el-form-item>
                </div>
                <div class="grid grid-cols-2 gap-8 border-b border-solid border-gray-200 pb-4 mb-5">
                    <div>
                        <el-form-item :label="$t('clients.payment_method')" prop="payment_method_id">
                            <el-select v-model="billingData.payment_method_id" :disabled="!enabledBilling" class="w-full">
                                <el-option
                                    v-for="method in paymentMethods"
                                    :key="method.id"
                                    :value="method.id"
                                    :label="paymentMethodNameFormatter(method.type)"
                                />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="Autogiro id" prop="autogiro_id">
                            <el-input v-model="billingData.autogiro_id" :disabled="billingData.invoice_type !== 'autogiro'" />
                        </el-form-item>
                    </div>
                    <div>
                        <el-form-item :label="$t('clients.has_rut_rot')">
                            <el-switch
                                v-model="billingData.has_rut"
                                :active-value="1"
                                :inactive-value="0"
                                :disabled="!enabledBilling"
                            />
                        </el-form-item>
                        <el-form-item :label="$t('clients.send_invoice_by_mail')">
                            <el-switch
                                v-model="billingData.invoice_by_mail"
                                :active-value="1"
                                :inactive-value="0"
                                :disabled="!enabledBilling"
                            />
                        </el-form-item>
                    </div>
                </div>

                <div class="grid grid-cols-2 gap-8 mb-8 ">
                    <div>
                        <el-form-item label="Orgnummer / Personummer" prop="per_org_nummer">
                            <el-input v-model="billingData.per_org_nummer" :disabled="!enabledBilling" />
                        </el-form-item>
                        <el-form-item :label="$t('clients.name')" prop="name">
                            <el-input v-model="billingData.name" :disabled="!enabledBilling" />
                        </el-form-item>
                        <el-form-item :label="$t('clients.surname')">
                            <el-input v-model="billingData.surname" :disabled="!enabledBilling" />
                        </el-form-item>
                        <el-form-item label="Email" prop="email">
                            <el-input v-model="billingData.email" :disabled="!enabledBilling" />
                        </el-form-item>
                    </div>
                    <div>
                        <el-form-item label="CO">
                            <el-input v-model="billingData.co" :disabled="!enabledBilling" />
                        </el-form-item>
                        <el-form-item :label="$t('clients.street')" prop="street">
                            <el-input v-model="billingData.street" :disabled="!enabledBilling" />
                        </el-form-item>
                        <el-form-item :label="$t('clients.post_code')" prop="post_code">
                            <el-input v-model="billingData.post_code" v-mask="'### ##'" :disabled="!enabledBilling" />
                        </el-form-item>
                        <el-form-item :label="$t('clients.city')" prop="city">
                            <el-input v-model="billingData.city" :disabled="!enabledBilling" />
                        </el-form-item>
                    </div>
                </div>
            </el-form>

            <div class="flex justify-end pt-3 mt-10 border-t border-gray-200">
                <el-button type="success" :disabled="!enabledBilling" :loading="$wait.is('updating')" @click="update">
                    {{ $t('common.update') }}
                </el-button>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import VueTheMask from 'vue-the-mask';

Vue.use(VueTheMask);

export default {
    props: {
        clientId: {
            type:    Number,
            default: null,
        },
    },

    data() {
        return {
            buttonDisabled: true,
            enabledBilling: false,
            clientUuid:     this.$route.params.uuid,
            billingData:    {
                client_type:     'private',
                has_rut:         0,
                invoice_by_mail: 0,
                invoice_type:    'normal',
                email:           '',
                name:            null,
                surname:         null,
                co:              null,
                street:          null,
                post_code:       null,
                city:            null,
                per_org_nummer:  null,
                autogiro_id:     null,
                bank_account_id: null,
            },
            paymentMethods: [],
            rules:          {
                invoice_type: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                email:        [
                    { required: true, message: this.$t('common.required'), trigger: 'blur' },
                    { type: 'email', message: this.$t('common.wrong_email_format'), trigger: 'blur' },
                ],
                name:              [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                street:            [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                post_code:         [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                city:              [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                per_org_nummer:    [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                payment_method_id: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
            },
        };
    },

    computed: {
        invoiceByMail() {
            return this.billingData.invoice_by_mail;
        },

        perOrgNummer() {
            return this.billingData.per_org_nummer;
        },

        invoiceType() {
            return this.billingData.invoice_type;
        },

        paymentMethod() {
            return this.billingData.payment_method_id;
        },

        hasRut() {
            return this.billingData.has_rut;
        },
    },

    watch: {
        paymentMethod() {
            const type = this.paymentMethods.filter(item => item.id === this.billingData.payment_method_id);
            this.billingData.invoice_type = type[0].type;
        },
        hasRut() {
            this.updateValidationRules();
        },
        perOrgNummer() {
            this.updateValidationRules();
        },
        invoiceByMail() {
            this.updateValidationRules();
        },
        invoiceType() {
            const autogiroRequired = this.billingData.invoice_type === 'autogiro';
            Vue.set(this.rules, 'autogiro_id',
                [
                    { required: autogiroRequired, message: this.$t('common.required'), trigger: 'blur' },
                ]);
        },
    },

    created() {
        this.getPaymentMethods();
        this.getBillingInformation();
    },

    methods: {
        async getBillingInformation() {
            this.$wait.start('loading_billing_information');
            try {
                const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${this.clientUuid}/billing_information`, {
                    requestId: 'justRequest',
                });
                this.enabledBilling = !!data.uuid;
                this.buttonDisabled = !data.uuid;
                this.billingData = data;
            } finally {
                this.$wait.end('loading_billing_information');
            }
        },

        async getPaymentMethods() {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/settings/payment_methods`);
            this.paymentMethods = data;
            if (data.length === 0) {
                this.billingData.enable_billing = false;
            }
        },

        async update() {
            if (this.enabledBilling === false) {
                try {
                    await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${this.clientUuid}/billing_information`, {
                        enable_billing: this.enabledBilling,
                    });
                    this.$wait.end('updating');
                    this.$notify.success({ title: this.$t('common.success') });
                    this.$refs.form.resetFields();
                } catch (error) {
                    this.$wait.end('updating');
                }

                return;
            }
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));
            this.$wait.start('updating');

            try {
                await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${this.clientUuid}/billing_information`, {
                    enable_billing:  this.enabledBilling,
                    invoice_type:    this.billingData.invoice_type,
                    has_rut:         this.billingData.has_rut ? 1 : 0,
                    invoice_by_mail: this.billingData.invoice_by_mail ? 1 : 0,
                    email:           this.billingData.email,
                    name:            this.billingData.name,
                    surname:         this.billingData.surname,
                    co:              this.billingData.co,
                    street:          this.billingData.street,
                    post_code:       this.billingData.post_code,
                    city:            this.billingData.city,
                    per_org_nummer:  this.billingData.per_org_nummer,
                    autogiro_id:     this.invoiceType === 'autogiro' ? this.billingData.autogiro_id : null,
                    bank_account_id: this.billingData.payment_method_id,
                });
                this.$notify.success({ title: this.$t('common.success') });
            } finally {
                this.$wait.end('updating');
            }
        },

        paymentMethodNameFormatter(type) {
            if (type === 'autogiro') {
                return 'Autogiro';
            }
            return this.$t('clients.bank_transfer');
        },

        updateValidationRules() {
            const invoiceByMailRules = this.billingData.invoice_by_mail !== 1;

            Vue.set(this.rules, 'email',
                [
                    { required: invoiceByMailRules, message: this.$t('common.required'), trigger: 'blur' },
                    { type: 'email', message: this.$t('common.wrong_email_format'), trigger: 'blur' },
                ]);
        },
    },
};
</script>
